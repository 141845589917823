import React from "react";

export default class Toggle extends React.Component {

    render() {
        return (
            <main className="toggle">
                <div className="theme-toggle theme-toggle-js">
                    <span className="moon" id={this.props.theme === "darkTheme" ? "lightTheme" : "darkTheme"} onClick={this.props.handleChange}></span>
                    <span className="sun" id={this.props.theme === "darkTheme" ? "lightTheme" : "darkTheme"} onClick={this.props.handleChange}></span>
                    <small className="sun__ray"></small>
                    <small className="sun__ray"></small>
                    <small className="sun__ray"></small>
                    <small className="sun__ray"></small>
                    <small className="sun__ray"></small>
                    <small className="sun__ray"></small>
                </div>
            </main>
        )
    }
}
