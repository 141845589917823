import React, { Component } from 'react';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Masonry } from 'masonic';

class ImgurGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            clientId: '57969584cb97023',
            isDesktop: false // Add state to track if it's desktop or not
        };
    }

    componentDidMount() {
        this.fetchImages();
        this.shuffleInterval = setInterval(this.fetchImages, 20000); // 20 seconds
        this.handleResize(); // Call handleResize to set initial state
        window.addEventListener('resize', this.handleResize); // Listen for resize events
    }

    componentWillUnmount() {
        clearInterval(this.shuffleInterval);
        window.removeEventListener('resize', this.handleResize); // Remove resize event listener
    }

    handleResize = () => {
        const isDesktop = window.innerWidth > 768; // Define desktop as screen width greater than 768px
        this.setState({ isDesktop });
    };

    fetchImages = async () => {
        try {
            const response = await axios.get('https://api.imgur.com/3/album/prdkZd7/images', {
                headers: {
                    Authorization: `Client-ID ${this.state.clientId}`,
                },
            });

            const imagesData = response.data.data;

            const images = imagesData.map((item) => ({
                key: item.id,
                src: item.link,
                width: 'auto', // Set width to 'auto'
                height: '100%', // Set height to '100%'
            }));

            this.setState({ images });
        } catch (error) {
            console.error('Error fetching images from Imgur:', error);
        }
    };

    render() {
        const { images, isDesktop } = this.state;

        return (
            <div>
                {isDesktop ? (
                    <div className='carousel-container'>
                        {images.length > 0 && (
                            <Carousel showThumbs={true} showIndicators={false}>
                                {images.map((image) => (
                                    <div key={image.key} style={{ height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={image.src} alt={`gallery-${image.key}`} style={{ width: image.width, height: image.height }} />
                                    </div>
                                ))}
                            </Carousel>
                        )}
                    </div>
                ) : (
                    <div>
                        {images.length > 0 && (
                            <Masonry
                                items={images}
                                columnGutter={0}
                                columnWidth={window.innerWidth / Math.floor(window.innerWidth / 300)}
                                render={({ index, data: { src, key, width, height } }) => {
                                    // Calculate the dynamic height to maintain aspect ratio
                                    const dynamicHeight = ((window.innerWidth / Math.floor(window.innerWidth / 300)) / width) * height;

                                    return (
                                        <div key={key} style={{ width: window.innerWidth / Math.floor(window.innerWidth / 300), height: dynamicHeight }}>
                                            <img src={src} alt={`gallery-${key}`} style={{ width: '100%', height: '100%' }} />
                                        </div>
                                    );
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default ImgurGallery;
