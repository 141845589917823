import React from "react";
import { GiTap } from "react-icons/gi";
import { GiThermometerCold } from "react-icons/gi";
import { LuPackageOpen } from "react-icons/lu";
import { IoInfiniteSharp } from "react-icons/io5";
import { GiAutoRepair } from "react-icons/gi";

export default class Services extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        var classList = document.getElementsByClassName("animate")[3].classList;
        console.log(classList)
        if (classList.contains('show')) {
            console.log(classList)
            var close = document.getElementsByClassName("burger")[0];
            console.log(close)
            close?.click();
        }
    }

    render() {
        return (
            <>
                <section id="home">
                    <div className={`jumbotron hello ${this.slideLeft}`}>
                        <div className="container">
                            <h1 className="display-4">Services</h1>
                            <p className="hello-sub">
                                Put your trust in us and we'll make sure you get the best price and service. We guarantee our prices are competitive and our service is second to none.
                            </p>
                        </div>
                    </div>
                    {/* Animated Scroll Button */}
                    <div id="helloScroll">
                        <a href="#sectionContent">
                            <span></span>
                        </a>
                    </div>
                </section>
                <section id="sectionContent">
                    <div className="serviceContent">
                        <div className="box">
                            <div className="icon"><LuPackageOpen /></div>
                            <div className="box-content">
                                <h3>Installations</h3>
                                <p> Experience seamless installations tailored to your needs. Whether it's setting up a new air conditioning system, plumbing fixtures, or other equipment, our skilled team ensures precision and reliability. Elevate your comfort with hassle-free installations that prioritize efficiency and performance.
                                </p>
                            </div>
                            {/* <a href="#"> more info </a> */}
                        </div>
                        <div className="box">
                            <div className="icon"><GiAutoRepair /></div>
                            <div className="box-content">
                                <h3>Repairs</h3>
                                <p> Our team offers exceptional service on repairs and maintenance for any of the issues in your home, business place, factory or caravans.Our dedicated team addresses any issues promptly, ensuring reliable solutions that stand the test of time. Trust us to keep your spaces functioning smoothly and efficiently.
                                </p>
                            </div>
                            {/* <a href="#"> more info </a> */}
                        </div><div className="box">
                            <div className="icon"><GiThermometerCold /></div>
                            <div className="box-content">
                                <h3>Air Conditioning</h3>
                                <p> Discover comfort in your space with our tailored A/C solutions. Whether it's split systems or ducted systems, we provide the best-fit options for your property. Elevate your environment with our expertise in creating optimal and personalized cooling solutions.
                                </p>
                            </div>
                            {/* <a href="#"> more info </a> */}
                        </div>

                        <div className="box">
                            <div className="icon"><GiTap /></div>
                            <div className="box-content">
                                <h3>Plumbing</h3>
                                <p> Say goodbye to plumbing woes with our expert solutions. From fixing leaks and clogs to installing new fixtures, our dedicated plumbing services cater to homes, businesses, and industrial spaces. Trust us to deliver prompt and efficient plumbing solutions that keep your systems running smoothly. Your satisfaction is our priority.
                                </p>
                            </div>
                            {/* <a href="#"> more info </a> */}
                        </div>
                        {/* <div className="box">
                            <div className="icon"> <IoInfiniteSharp /></div>
                            <div className="box-content">
                                <h3> Other </h3>
                                <p> Dive into a range of services beyond air conditioning and repairs. Whether it's crafting decks, constructing retaining walls, or other home improvement projects, we handle a diverse array of tasks. Our skilled team ensures quality results with meticulous attention to detail. Trust us for reliable solutions, regardless of the size or nature of the project. Your satisfaction is our unwavering commitment.
                                </p>
                            </div>
                             <a href="#"> more info </a> 
                        </div> */}
                    </div>
                </section>
            </>
        )
    }
}
