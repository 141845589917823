import React from "react";
import logoSVG from "../logo.svg";
//import DropboxGallery from "./ImgurGallery.js"; // Import the DropboxGallery component
import ImgurGallery from "./ImgurGallery.js";

export default class Home extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        var classList = document.getElementsByClassName("animate")[3].classList;
        console.log(classList)
        if (classList.contains('show')) {
            console.log(classList)
            var close = document.getElementsByClassName("burger")[0];
            console.log(close)
            close?.click();
        }
    }

    render() {
        return (
            <>
                <section id="home">
                    <div className={`jumbotron hello ${this.slideLeft}`}>
                        <div className="container">
                            <img src={logoSVG} alt="Humeco Logo" />
                        </div>
                    </div>
                    {/* Animated Scroll Button */}
                    <div id="helloScroll">
                        <a href="#sectionContent">
                            <span></span>
                        </a>
                    </div>
                </section>
                <div id="sectionContent">
                    <br></br>
                    <h1 className="display-4">WELCOME TO HUMECO SOLUTIONS</h1>
                    <i>
                        <p>Your trusted HVAC Solutions</p>
                        <p>Based in the Northern Suburbs of Melbourne</p>
                    </i>
                </div>

                {/* Add the DropboxGallery component here */}
                <div id="imageGallery">

                    <ImgurGallery />
                </div>
            </>
        )
    }
}
