import React from "react";

import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

export default class Footer extends React.Component {

    render() {
        return (
            <footer className={`${this.slideUp}`}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <a className='abn'>ABN 42 661 411 945</a>
                    <a href="https://www.instagram.com/humecoau/">
                        <FaInstagram />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61559365537513&mibextid=LQQJ4d">
                        <FaFacebook />
                    </a>
                    <a href="https://www.linkedin.com/company/humeco-solutions-pty-ltd">
                        <FaLinkedin />
                    </a>
                    <a className='abn'>ACN 661 411 945</a>
                </div>
            </footer>
        );
    }
}