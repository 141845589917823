import React from "react";
import validator from "validator";
import { RiPhoneFill } from "react-icons/ri"
import { IoMdMail } from "react-icons/io"
import { FaMapMarked } from "react-icons/fa"

export default class Contact extends React.Component {

    state = {
        name: "",
        email: "",
        phone: "",
        message: "",
        nameErr: false,
        emailErr: false,
        phoneErr: false,
        messErr: false
    };

    animate = (e) => {
        console.log((e.target).classList);
        (e.target).classList.add('animate');
        setTimeout(function () {
            e.target.classList.remove('animate');
        }, 700);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var classList = document.getElementsByClassName("animate")[3].classList;
        console.log(classList)
        if (classList.contains('show')) {
            console.log(classList)
            var close = document.getElementsByClassName("burger")[0];
            console.log(close)
            close?.click();
        }
    }

    render() {
        return (
            <section id="contact">
                <div className={`jumbotron helloC ${this.slideLeft}`}>
                    <div className="container">
                        <div id="modal">
                            <div className="modal-content">
                                <iframe id="iframe"
                                    title="map"
                                    frameBorder={0}
                                    src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=15%20Nethercote%20St+(Humeco%20Solutions%20PTY%20LTD)&t=&z=14&ie=UTF8&iwloc=B&output=embed">
                                </iframe>
                                <a href="#" className="close">x</a>
                            </div>
                        </div>
                        <h1 className="display-4">CONTACT</h1>
                        <div className="sm-view">
                            <a href="tel:+61406963075" className="open-modal bubbly-button" onClick={(e) => { this.animate(e) }}><RiPhoneFill /></a>
                            <a href="mailto: info@humeco.com.au" target="_blank" className="open-modal bubbly-button" onClick={(e) => { this.animate(e) }} rel="noreferrer"><IoMdMail /></a>
                            <a className="open-modal bubbly-button" href="#modal">
                                <FaMapMarked />
                            </a>
                        </div>
                        <div className="container">
                            <div className="form-container">
                                <div className="left-container">
                                    <div className="left-inner-container">
                                        <div className="parallelogram lt">
                                            <a href="tel:+61406963075"><span id="lt" style={{ marginTop: '10%' }}>0406 963 075</span><div className="inner"><RiPhoneFill /></div></a>
                                        </div>
                                        <div className="parallelogram lt">
                                            <a href="mailto: info@humeco.com.au" target="_blank" rel="noreferrer"><span id="lt" style={{ marginTop: '10%' }}>INFO@HUMECO.COM.AU</span><div className="inner"><IoMdMail /></div></a>

                                        </div>
                                        <div className="parallelogram lt">
                                            <a className="open-modal" href="#modal">
                                                <span id="lt" style={{ marginTop: '4%' }}>21 Prominence Rise
                                                    <br />CRAIGIEBURN
                                                    <br />VIC 3064</span>
                                                <div className="inner">
                                                    <FaMapMarked />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-container">
                                    <div className="right-inner-container">
                                        <form action="">
                                            {this.state.nameErr ? this.state.name ? <br></br> : <p className="error">Name can't be empty</p> : <br></br>}
                                            <input id="name" type="text" placeholder="Name" onChange={this.props.validate}
                                                onInputCapture={(e) => {
                                                    this.props.handleState(e); this.setState({ name: e.target.value })
                                                }} onBlurCapture={() => { this.setState({ nameErr: true }) }} />

                                            {this.state.emailErr ? validator.isEmail(this.state.email) ? <br></br> : <p className="error">Invalid email address</p> : <br></br>}
                                            <input id="email" type="email" placeholder="Email" onInputCapture={(e) => {
                                                this.props.handleState(e); this.setState({ email: e.target.value })
                                            }} onBlurCapture={() => { this.setState({ emailErr: true }) }} />

                                            {this.state.phoneErr ? validator.isMobilePhone(this.state.phone, "en-AU") ? <br></br> : <p className="error">Invalid phone number</p> : <br></br>}
                                            <input id="phone" type="phone" placeholder="Phone" onChange={this.props.validate} onInputCapture={(e) => {
                                                this.props.handleState(e); this.setState({ phone: e.target.value })
                                            }} onBlurCapture={() => { this.setState({ phoneErr: true }) }} />

                                            {this.state.messErr ? this.state.message ? <br></br> : <p className="error">Message can't be empty</p> : <br></br>}
                                            <textarea id="message" rows="10" placeholder="Message" onChange={this.props.validate} onInputCapture={(e) => {
                                                this.props.handleState(e); this.setState({ message: e.target.value })
                                            }} onBlurCapture={() => { this.setState({ messErr: true }) }} />

                                            <button
                                                onClick={(e) => { e.preventDefault(); this.props.sendMail(e) }}
                                                disabled={this.props.allowSubmit ? false : true}>
                                                Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}
