import React, { useLayoutEffect, useState, Component } from "react";
import logoSVG from "./logo.svg";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Navbar from "@dbaeka/react-navbar";
import validator from "validator";
import { Helmet } from "react-helmet";

import "./App.scss";

//Import Components
import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Toggle from "./components/Toggle";
import Footer from "./components/Footer";

//Cookies
import Cookies from "universal-cookie";

// FIREBASE
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoHEA7s6Hl_6ovhxikdGnEVQe_J8xXcWU",
  authDomain: "humeco-web.firebaseapp.com",
  projectId: "humeco-web",
  storageBucket: "humeco-web.appspot.com",
  messagingSenderId: "487120421432",
  appId: "1:487120421432:web:ae789c858a7f39ac3b5ccd",
  measurementId: "G-915WFJV96B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
var db = getFirestore(app);

class App extends Component {
  state = {
    theme: "",
    key: "",
    // activeTab: "home",
    //pos: window.scrollY,
    name: "",
    email: "",
    phone: "",
    message: "",
    allowSubmit: false,
  };

  //Functions
  handleChange = (e: { target: { id: any } }) => {
    const id = e.target.id;
    console.log(id);
    this.cookies.set("theme", id, { path: "/" });

    this.setState({
      theme: id,
    });
  };

  // handleScroll = (a: any) => {
  //   this.setState({
  //     activeTab: a,
  //   });
  // };
  validate = () => {
    if (
      !validator.isEmpty(this.state.name) &&
      validator.isEmail(this.state.email) &&
      validator.isMobilePhone(this.state.phone, "en-AU") &&
      !validator.isEmpty(this.state.message)
    ) {
      this.setState({ allowSubmit: true });
    } else {
      this.setState({ allowSubmit: false });
    }
  };

  handleState = (e: {
    target: {
      value: any;
      id: any;
    };
  }) => {
    const id = e.target.id;
    console.log(id);

    this.setState({
      [id]: e.target.value,
    });

    this.validate();
  };

  setTheme() {
    if (this.state.theme === "darkTheme") {
      document.body.className = "dark theme--night";
    } else {
      document.body.className = "light";
    }
  }

  //Init cookies
  cookies = new Cookies();

  componentDidMount() {
    // window.addEventListener("scroll", this.resizeHeaderOnScroll);
  }
  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      nav = document.getElementsByClassName("navbar")[0];

    if (distanceY > shrinkOn) {
      nav?.classList.add("smaller");
    } else {
      nav?.classList.remove("smaller");
    }
  }

  render() {
    const addToDB = async () => {
      if (this.state.allowSubmit === true) {
        try {
          const docRef = await addDoc(collection(db, "users"), {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
          });
          console.log("Document written with ID: ", docRef.id);
          alert("Message Successfully Sent");
          this.setState({
            allowSubmit: false,
          });
        } catch (e) {
          console.error("Error adding document: ", e);
          alert(
            "Error sending Message, Please try another method of contact or Refresh and try again."
          );
        }
      }
    };

    //Get theme from cookies
    if (this.cookies.get("theme") === null) {
      console.log("No theme set, setting light as default");
      this.cookies.set("theme", "lightTheme", { path: "/" });
      this.setState((state) => ({
        theme: "lightTheme",
      }));
    } else if (this.cookies.get("theme") === this.state.theme) {
      console.log(`The ${this.state.theme} has been previously selected`);
    } else {
      console.log(this.cookies.get("theme"));
      this.setState((state) => ({
        theme: this.cookies.get("theme"),
      }));
      console.log(`state is ${this.state.theme}`);
    }

    //set theme
    this.setTheme();

    //NAVBAR
    const navTheme = {
      mainColor: "#0d6efd",
      menuBgColor: this.state.theme == "darkTheme" ? "#141d26" : "#e8f2fc",
    };

    const navBrand = (
      <a href={"/"}>
        <img id="logo" src={logoSVG} width="100px"></img>
      </a>
    );

    const leftLinks = (
      <>
        <a href="tel:+61406963075" className="phone">
          <>
            <svg
              id="phone"
              x="0px"
              y="0px"
              viewBox="0 0 82 82"
              xmlSpace="preserve"
            >
              <path
                d="M64.5,78.2c1.7-1.9,3.6-3.6,5.4-5.4c2.6-2.7,2.7-5.9,0-8.6c-3.1-3.2-6.3-6.3-9.4-9.4
							c-2.6-2.6-5.8-2.6-8.4,0c-2,1.9-3.9,3.9-5.9,5.9c-0.1,0.1-0.3,0.2-0.4,0.3l-1.3,1.3c-0.4,0.2-0.7,0.2-1.2,0
							c-1.3-0.7-2.6-1.2-3.8-2c-5.7-3.6-10.5-8.2-14.7-13.4c-2.1-2.6-4-5.3-5.3-8.4c-0.2-0.5-0.2-0.9,0.1-1.3l1.3-1.3
							c0.1-0.1,0.1-0.2,0.2-0.3c0.6-0.6,1.2-1.1,1.8-1.7c1.4-1.3,2.7-2.7,4.1-4.1c2.7-2.7,2.7-5.9,0-8.6c-1.5-1.5-3.1-3.1-4.6-4.6
							c-1.6-1.6-3.2-3.2-4.8-4.8c-2.6-2.5-5.8-2.5-8.4,0c-2,1.9-3.9,3.9-5.9,5.9c-1.9,1.8-2.8,3.9-3,6.5c-0.3,4.1,0.7,8,2.1,11.8
							C5.2,43.8,9.6,50.7,15,57.1c7.2,8.6,15.9,15.4,26,20.4c4.6,2.2,9.3,3.9,14.4,4.2C58.9,81.8,62,81,64.5,78.2z"
              />
              <path
                d="M41.1,15.7
							c-0.7,0-1.5,0.1-2.2,0.4c-1.7,0.8-2.5,2.8-2,4.8c0.4,1.8,2,3,3.9,3c4.6,0.1,8.6,1.5,12,4.6c3.7,3.4,5.4,7.7,5.6,12.8
							c0,0.9,0.4,1.9,0.9,2.6c1.1,1.5,3,1.9,4.8,1.2c1.6-0.6,2.5-2,2.5-3.9c-0.1-7-2.6-12.9-7.5-18.1C54.1,18.4,48.1,15.8,41.1,15.7z"
              />
              <path
                d="M69,11.4c8.5,8.7,12.5,18.1,12.8,29.1c0.1,2.5-1.5,4.2-3.9,4.3c-2.6,0.1-4.3-1.4-4.4-4c-0.1-5.4-1.4-10.5-4-15.2
							C63.5,14.9,54.2,9.3,42,8.6c-1.4-0.1-2.6-0.2-3.6-1.3c-1.2-1.4-1.3-3-0.7-4.6c0.7-1.6,2-2.4,3.8-2.4c8,0.1,15.3,2.4,22,6.8
         C65.7,8.6,67.8,10.4,69,11.4z"
              />
            </svg>
            <span>0406 963 075</span>
          </>
        </a>
      </>
    );

    const rightLinks = (
      <>
        <NavLink to={"/"}>HOME</NavLink>
        <NavLink to={"/services"}>SERVICES</NavLink>
        <NavLink to={"/about"}>ABOUT</NavLink>
        <NavLink to={"/contact"}>CONTACT</NavLink>
      </>
    );

    return (
      <>
        <Helmet>
          <title>
            HUMECO SOLUTIONS PTY LTD - Plumbing, Heating, Air Conditioning in
            Melbourne, Victoria, Australia"
          </title>
          <meta
            name="description"
            content={
              "Humeco Solutions offer plumbing, heating, and air conditioning services in Greater Melbourne Area, Victoria, Australia. Contact us for reliable solutions."
            }
          />
        </Helmet>
        <Helmet>
          <meta
            property="og:title"
            content={"Humeco Solutions - Plumbing, Heating, Air Conditioning"}
          />
          <meta
            property="og:description"
            content={
              "Providing plumbing, heating, and air conditioning services in Melbourne, Victoria, Australia."
            }
          />
          <meta property="og:image" content={logoSVG} />
          <meta property="og:url" content={"https://humeco.com.au"} />
          <meta property="og:type" content="website" />
        </Helmet>
        <Helmet>
          <link rel="canonical" href="https://humeco.com.au" />
        </Helmet>

        <BrowserRouter>
          <Navbar
            className="navbar" // style .navbar in your css
            menuClassName="navbar--menu" // style .navbar--menu in your css
            brand={navBrand}
            theme={navTheme}
            leftLinks={leftLinks}
            rightLinks={rightLinks}
            shouldHideOnScroll={false}
          />
          <div className="App">
            <header className="App-header">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                    //handleScroll={this.handleScroll}
                    />
                  }
                />
                <Route
                  path="services"
                  element={
                    <Services
                      handleChange={this.handleChange}
                      theme={this.state.theme}
                    />
                  }
                />
                <Route path="about" element={<About />} />
                <Route
                  path="contact"
                  element={
                    <Contact
                      handleState={this.handleState}
                      sendMail={addToDB}
                      allowSubmit={this.state.allowSubmit}
                      validate={this.validate}
                      state={this.state}
                    />
                  }
                />
              </Routes>
            </header>
          </div>
          <Toggle handleChange={this.handleChange} theme={this.state.theme} />
          <Footer />
        </BrowserRouter>
      </>
    );
  }
}

export default App;
