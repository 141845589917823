import React from "react";
import Aboutimg from "../images/aboutimg.jpg"

export default class About extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        var classList = document.getElementsByClassName("animate")[3].classList;
        console.log(classList)
        if (classList.contains('show')) {
            console.log(classList)
            var close = document.getElementsByClassName("burger")[0];
            console.log(close)
            close?.click();
        }
    }

    render() {
        return (
            <>
                <section id="home">
                    <div className={`jumbotron hello ${this.slideLeft}`}>
                        <div className="container">
                            <h1 className="display-4">About Us</h1>
                            <p className="hello-sub">
                                Highest Quality Professional Services guaranteed
                            </p>
                        </div>
                    </div>
                    {/* Animated Scroll Button */}
                    <div id="helloScroll">
                        <a href="#sectionContent">
                            <span></span>
                        </a>
                    </div>
                </section>
                <section className='sectionContent' id="sectionContent">
                    <br></br>
                    <h1 className="display-4">HUMECO SOLUTIONS PTY LTD</h1>
                    <p class='small'>ABN 42 661 411 945</p>
                    <p class='small'>ACN 661 411 945</p>
                    <div className='aboutC'>
                        <div className='aboutText'>
                            <p>
                                HumeCo Solutions, based in the Nothern suburbs of Melbourne, is a comprehensive provider of Heating, Ventilation, Air Conditioning, Plumbing and Property Maintenance services. With years of experience and a team of highly skilled technicians, we are dedicated to delivering exceptional customer service and high-quality workmanship. Whether you need routine maintenance for your HVAC system, Plumbing repairs, or a complete property overhaul, be it Industrial, Commercial or Residential HumeCo Solutions is here to help.
                            </p>
                            <p>
                                Our commitment to using the latest technologies and best practices ensures that your building is comfortable, safe, and running efficiently. Let us take care of all your property needs, and enjoy peace of mind knowing that your building is in good hands with HumeCo Solutions.
                            </p>
                        </div>

                        <img className='aboutIMG' src={Aboutimg} alt="About Image" />
                    </div>
                </section>
            </>
        )
    }
}
